import React, { useState } from 'react';
import { Bell } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import Home from './pwapp/Home';

// Options component  
const Options = ({ t }) => {
    const [theme, setTheme] = useState('light');

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };

    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">{t('app_options_title')}</h1>
            <div className="mb-4">
                <label className="block font-medium mb-2" htmlFor="theme">{t('app_theme_label')}</label>
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="theme"
                        checked={theme === 'dark'}
                        onChange={toggleTheme}
                        className="mr-2"
                    />
                    <span>{theme === 'dark' ? t('app_theme_dark') : t('app_theme_light')}</span>
                </div>
            </div>
        </div>
    );
};

// Notifications component
const Notifications = ({ t }) => {
    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">{t('app_notifications_title')}</h1>
            {/* Add notifications content here */}
        </div>
    );
};

// About component
const About = ({ t }) => {
    return (
        <div>
            <h1 className="text-2xl font-bold mb-4">{t('app_about_title')}</h1>
            <p>{t('app_description')}</p>
        </div>
    );
};

// App component
const App = () => {
    const [currentPage, setCurrentPage] = useState('home');
    const [notifications, setNotifications] = useState(5); // Example number of notifications

    const { t } = useTranslation();

    const renderPage = () => {
        switch (currentPage) {
            case 'options':
                return <Options t={t} />;
            case 'notifications':
                return <Notifications t={t} />;
            case 'about':
                return <About t={t} />;
            default:
                return <Home t={t} />;
        }
    };

    return (
        <div className="flex flex-col h-screen  font-['Space_Grotesk']">
            {/* Navigation */}
            <nav className="bg-gray-800 text-white py-4 px-6 flex items-center space-x-6">

                <a href="/app" className='flex opacity-80'>
                    <img src="logo-bell-law-removebg-preview.png" alt="LawTracker Logo" className="h-20" />
                </a>
                <a href="/app" className="flex items-center opacity-90">
                    <span className="text-white text-2xl tracking-wide">
                    {t('app_name')}
                    </span>
                </a>

                <ul className="flex space-x-4 pl-4 items-center">
                    <li>
                        <button
                            className={`hover:text-gray-300 ${
                                currentPage === 'home' ? 'font-bold' : ''
                            }`}
                            onClick={() => setCurrentPage('home')}
                        >
                            {t('app_nav_home')}
                        </button>
                    </li>
                    <li>
                        <button
                            className={`hover:text-gray-300 ${
                                currentPage === 'options' ? 'font-bold' : ''
                            }`}
                            onClick={() => setCurrentPage('options')}
                        >
                            {t('app_nav_options')}
                        </button>
                    </li>
                    <li>
                        <button
                            className={`hover:text-gray-300 ${
                                currentPage === 'about' ? 'font-bold' : ''
                            }`}
                            onClick={() => setCurrentPage('about')}
                        >
                            {t('app_nav_about')}
                        </button>
                    </li>
                    <li>
                        <button
                            className={`hover:text-gray-300 ${
                                currentPage === 'notifications' ? 'font-bold' : ''
                            }`}
                            onClick={() => setCurrentPage('notifications')}
                        >
                            <div className="relative">
                                <Bell size={24} />
                                {notifications > 0 && (
                                    <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                                        {notifications}
                                    </span>
                                )}
                            </div>
                        </button>
                    </li>
                </ul>
            </nav>

            {/* Content */}
            <div className="flex-1 p-6">{renderPage()}</div>
        </div>
    );
};

export default App;
